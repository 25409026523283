import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { HiMinus, HiPlus } from "react-icons/hi2";
import { submitFormData, imageUpload } from "../../../../api/api-calls";
import { Alert } from "../../../../components/forms/alert";
import { BtnLoader } from "../../../../components/btnLoader";

export const PartnersAndDonors = ({ data }) => {
    const [content, updateContent] = useState(data?.content);
    const [showForm, setShowForm] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [imgLoading, setImgLoading] = useState(false);
    const [error, setError] = useState("");

    const { enqueueSnackbar } = useSnackbar();

    const submitData = () => {
        setError("");
        setBtnLoading(true);
        submitFormData("post", `content/update/${data.id}`, {
            ...data,
            content: content,
        })
            .then(() => {
                enqueueSnackbar(`Section update successfully`, {
                    variant: "success",
                });
            })
            .catch((response) => {
                if (response.statusText === "Unauthorized") {
                    localStorage.setItem("token", "");
                    window.location.replace("/login");
                } else {
                    setError(
                        response?.data?.error ||
                            "Unable to submit, please refresh your page and try again"
                    );
                }
            })
            .finally(() => {
                setBtnLoading(false);
            });
    };

    const handleFileUpload = (event) => {
        const files = Array.from(event.target.files);

        const uploadPromises = files.map((file) => {
            setImgLoading(true);
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.onload = (event) => {
                    const blob = new Blob([event.target.result], {
                        type: file.type,
                    });
                    const formData = new FormData();
                    formData.append("file", blob);
                    formData.append("folder", "logos/partners_and_donors/");
                    formData.append("file_name", file.name);

                    imageUpload(formData)
                        .then((response) => resolve(response.data.file_path))
                        .catch((error) => {
                            console.error("Error uploading file: ", error);
                            reject(error);
                        });
                };
                fileReader.readAsArrayBuffer(file);
            });
        });

        Promise.all(uploadPromises)
            .then((updatedImages) => {
                updateContent({
                    ...content,
                    images: [
                        ...content.images,
                        ...updatedImages.map((image) => ({
                            name: "",
                            link: "",
                            image,
                        })),
                    ],
                });
            })
            .catch((error) => {
                console.error("Error uploading files: ", error);
            })
            .finally(() => {
                setImgLoading(false);
            });
    };

    const updateItem = (index, key, event) => {
        const newArray = [...content.images];
        newArray[index][key] = event?.target?.value || event;
        updateContent({ ...content, images: newArray });
    };

    return (
        <div className="w-full lg:w-8/12 rounded-lg shadow-lg mx-auto">
            <div
                className={`flex items-center justify-between bg-teal-900 text-white p-2 cursor-pointer ${
                    showForm ? "rounded-t-lg" : "rounded-lg"
                }`}
                onClick={() => setShowForm(!showForm)}
            >
                <h2 className="text-xl font-semibold">Partners and Donors</h2>
                {showForm ? (
                    <HiMinus className="text-xl text-white" />
                ) : (
                    <HiPlus className="text-xl text-white" />
                )}
            </div>

            {showForm && (
                <div className="p-5">
                    {error && <Alert type="error" message={error} />}
                    <div className="grid grid-cols-4 gap-4 mb-5">
                        {content.images.map((image, i) => (
                            <div key={i} className="text-center">
                                <div className="flex flex-col space-y-4">
                                    <img
                                        src={
                                            process.env.REACT_APP_IMAGEKIT_URL +
                                            image.image
                                        }
                                        alt={image.name}
                                        className="h-12 w-auto mx-auto"
                                    />
                                    <input
                                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-lg p-3 leading-tight focus:outline-none focus:border-gray-500"
                                        type="text"
                                        placeholder="Enter partner's name"
                                        value={image.name}
                                        onChange={(event) => {
                                            updateItem(i, "name", event);
                                        }}
                                    />
                                    <input
                                        className="appearance-none block w-full text-gray-700 border border-gray-200 rounded-lg p-3 leading-tight focus:outline-none focus:border-gray-500"
                                        type="text"
                                        placeholder="Enter partner's website"
                                        value={image.link}
                                        onChange={(event) => {
                                            updateItem(i, "link", event);
                                        }}
                                    />
                                    <button
                                        type="button"
                                        className="bg-red-600 hover:bg-red-800 text-white font-medium rounded-lg text-sm pt-3 px-4 pb-[0.8em] transition duration-150 ease-in-out"
                                        onClick={() => {
                                            updateContent({
                                                ...content,
                                                images: content.images.filter(
                                                    (image, index) =>
                                                        index !== i
                                                ),
                                            });
                                        }}
                                    >
                                        DELETE
                                    </button>
                                </div>
                            </div>
                        ))}
                        <div className="flex flex-col items-center mb-5 justify-center">
                            <input
                                id="uploadImage"
                                className="opacity-0"
                                type="file"
                                accept="image/*"
                                multiple
                                onChange={handleFileUpload}
                            />
                            <button
                                type="button"
                                className="bg-gray-500 hover:bg-gray-600 w-fit text-white font-medium rounded-lg text-sm pt-3 px-4 pb-[0.8em] transition duration-150 ease-in-out"
                                onClick={() => {
                                    document
                                        .getElementById("uploadImage")
                                        .click();
                                }}
                            >
                                {imgLoading ? (
                                    <BtnLoader />
                                ) : (
                                    <span className="tracking-widest">
                                        Upload More Images
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                    <button
                        type="button"
                        disabled={btnLoading}
                        className={` ${
                            btnLoading
                                ? "bg-gray-100"
                                : " bg-teal-900 hover:bg-teal-950"
                        } text-white font-medium rounded-lg text-sm pt-3 px-4 pb-[0.8em] transition duration-150 ease-in-out`}
                        onClick={() => {
                            submitData();
                        }}
                    >
                        {btnLoading ? (
                            <BtnLoader />
                        ) : (
                            <span className="tracking-widest">SUBMIT</span>
                        )}
                    </button>
                </div>
            )}
        </div>
    );
};
